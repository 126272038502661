html, body {
  margin: 0;
  padding: 0;
}

body {
  background-color: #fff;
  width: 100%;
  height: 100%;
  font-family: Helvetica, sans-serif;
}

#window-chrome {
  display: none;
}

img {
  user-drag: none;
  -moz-user-drag: none;
  -webkit-user-drag: none;
}

#xp-window {
  text-align: center;
  margin: auto;
}

.xp-image, .xp-image img, .xp-image canvas {
  display: inline-block;
}

.xp-image {
  margin-top: 38px;
}

.focused img, .focused canvas {
  box-shadow: 0 0 9px #2dd5ffbf;
}

.waiting img, .waiting canvas {
  box-shadow: 0 0 9px #f2ff3fbf;
}

.turn-timer {
  color: #999;
  text-align: center;
  padding: 10px 0;
  font-size: 13px;
}

.user-count-wrapper {
  text-align: center;
  position: absolute;
  top: 10px;
}

.user-count {
  color: #906f95;
  background-color: #ff6;
  padding: 4px 12px;
  font-size: 12px;
}

.count {
  color: #61226b;
  padding-right: 12px;
  font-weight: 500;
}

@media only screen and (width >= 320px) and (width <= 480px), (width <= 568px) {
  #xp-window {
    background: none;
    width: auto;
    height: auto;
  }

  .xp-image {
    background: #000;
    padding: 5px;
  }

  .xp-image, .xp-image img, .xp-image canvas {
    width: 310px;
    height: 234px;
    margin-top: 0;
    display: block;
  }

  .user-count-wrapper {
    position: relative;
  }

  .count {
    padding: 0;
  }
}
/*# sourceMappingURL=index.ee1cf0e6.css.map */
