html, body {
	margin: 0;
	padding: 0;
  }
  
  body {
	width: 100%;
	height: 100%;
	background-color: #fff;
	font-family: Helvetica, sans-serif;
  }
  
  #window-chrome {
	display: none;
  }
  
  img {
	user-drag: none;
	-moz-user-drag: none;
	-webkit-user-drag: none;
  }
  
  #xp-window {
	/*cursor: none; /* let windows do that ;) */
/*	background: url(../../static/macbook.png) no-repeat center;
	background-size: 100% 100%;
	width: 690px;
	height: 460px; */
	margin: auto;
	text-align: center;
  }
  
  .xp-image, .xp-image img, .xp-image canvas {
	/*width: 500px;
	height: 375px;*/
	display: inline-block;
  }
  
  .xp-image {
	margin-top: 38px;
  }
  
  .focused img, .focused canvas {
	box-shadow: 0px 0px 9px 0px rgba(45, 213, 255, 0.75);
	-moz-box-shadow: 0px 0px 9px 0px rgba(45, 213, 255, 0.75);
	-webkit-box-shadow: 0px 0px 9px 0px rgba(45, 213, 255, 0.75);
  }
  
  .waiting img, .waiting canvas {
	box-shadow: 0px 0px 9px 0px rgba(242, 255, 63, 0.75);
	-moz-box-shadow: 0px 0px 9px 0px rgba(242, 255, 63, 0.75);
	-webkit-box-shadow: 0px 0px 9px 0px rgba(242, 255, 63, 0.75);
  }
  
  .turn-timer {
	font-size: 13px;
	color: #999;
	padding: 10px 0;
	text-align: center;
  }
  
  .user-count-wrapper {
	text-align: center;
	position: absolute;
	top: 10px;
  }
  
  .user-count {
	background-color: #FFFF66;
	padding: 4px 12px;
	font-size: 12px;
	color: #906F95;
  }
  
  .count {
	color: #61226B;
	padding-right: 12px;
	font-weight: 500;
  }
  
  @media only screen and (min-width : 320px) and (max-width : 480px), (max-width : 568px) {
	#xp-window {
	  background: none;
	  height: auto;
	  width: auto;
	}
  
	.xp-image {
	  padding: 5px;
	  background: #000;
	}
  
	.xp-image, .xp-image img, .xp-image canvas {
	  width: 310px;
	  margin-top: 0;
	  height: 234px;
	  display: block;
	}
  
	.user-count-wrapper {
	  position: relative;
	}
  
	.count {
	  padding: 0;
	}
  }
